import {
  ref, onMounted, getCurrentInstance, computed
} from 'vue'
import { reportingUseCase } from '@/domain/usecase'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const { $toast } = app!.appContext.config.globalProperties
    const data = ref([])
    const route = useRoute()
    const router = useRouter()
    const inputSearch = ref('')
    const iconPath = ref('')
    const typeMenu = ref('')
    const filters = {
      pageNumber: 1,
      pageSize: 10,
      totalRecords: 0,
      search: '',
      filterField: ['title'],
      custom: [] as any
    }
    const getAllData = async () => {
      store.dispatch('showLoading')
      ///
      const response = await reportingUseCase.getAll(filters)
      console.log('response', response)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
      } else {
        filters.totalRecords = response.result.Count
        data.value = await response.result.Data
      }
      store.dispatch('hideLoading')
    }

    const changeSearch = (val: any) => {
      console.log('search val', val)
      filters.search = val
      filters.pageNumber = 1
      getAllData()
    }

    const showDetailReportSetting = (val: any) => {
      console.log('valDetail', val)
      if (store.getters['appActiveUser/getAgent']?.id) {
        router.push({ name: 'report-setting-data-detail', params: { id: val.Id } })
      }
    }

    const filterChange = (val: any) => {
      filters.custom = []
      if (val.value !== 'all') {
        filters.custom.push(['type', '=', val.value])
      }
      filters.pageNumber = 1
      getAllData()
    }

    const onPage = (event: any) => {
      filters.pageNumber = event.page + 1
      getAllData()
    }

    onMounted(() => {
      getAllData()
    })

    return {
      data,
      inputSearch,
      iconPath,
      changeSearch,
      showDetailReportSetting,
      typeMenu,
      filterChange,
      filters,
      onPage
    }
  }
}
